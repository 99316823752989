import React from 'react';
import { useHistory } from 'react-router';
import { Routes } from '../../routes';
import { AdminRoutes } from '../adminRoutes';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { Trans } from '@lingui/react';

interface Params {
  history: ReturnType<typeof useHistory>;
}

export const getSidebarItems = ({ history }: Params) => {
  return [
    {
      id: 'home',
      text: <Trans id="NAVBAR.HOME">Home</Trans>,
      icon: HomeOutlinedIcon,
      items: [
        {
          id: 'insights',
          text: <Trans id="NAVBAR.INSIGHTS">Insights</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/insights`);
          },
        },
        {
          id: 'mav2',
          text: <Trans id="NAVBAR.MAV2">Matching Algorithm v2</Trans>,
          onClick: () => {
            history.push(AdminRoutes.MAv2.path);
          },
        },
      ],
    },
    {
      id: 'schools',
      text: <Trans id="NAVBAR.SCHOOLS">Schools</Trans>,
      icon: SchoolOutlinedIcon,
      items: [
        {
          id: 'universities',
          text: <Trans id="NAVBAR.EDIT_UNIVERSITY">Universities</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/universities`);
          },
        },
        {
          id: 'faculties',
          text: <Trans id="NAVBAR.FACULTIES">Faculties</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/faculties`);
          },
        },
        {
          id: 'terms',
          text: <Trans id="NAVBAR.TERMS">Terms</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/terms`);
          },
        },
      ],
    },
    {
      id: 'users',
      text: <Trans id="NAVBAR.USERS">Users'</Trans>,
      icon: PeopleAltOutlinedIcon,
      items: [
        {
          id: 'edit-users',
          text: <Trans id="NAVBAR.EDIT_USER_DATA">Edit</Trans>,
          onClick: () => {
            history.push(AdminRoutes.Users.SearchUsers.path);
          },
        },
        {
          id: 'paid-coaches',
          text: <Trans id="NAVBAR.COACHES">Paid Coaches</Trans>,
          onClick: () => {
            history.push(AdminRoutes.Users.PaidCoachApplications.path);
          },
        },
        {
          id: 'email-verification',
          text: (
            <Trans id="NAVBAR.MANAGES_EMAIL_VERIFICATION">
              Email Verification
            </Trans>
          ),
          onClick: () => {
            history.push(AdminRoutes.Users.VerificationEmail.path);
          },
        },
        {
          id: 'cashout-pending',
          text: <Trans id="NAVBAR.CASHOUT_PENDING">Cashout Pending</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/cashout-pending`);
          },
        },
      ],
    },
    {
      id: 'equilibrium',
      text: <Trans id="NAVBAR.EQUILIBRIUM">Equilibrium</Trans>,
      icon: BalanceOutlinedIcon,
      items: [
        {
          id: 'incentives',
          text: <Trans id="NAVBAR.PROMOTIONS_AND_MESSAGES">Incentives</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/incentives`);
          },
        },
        {
          id: 'price-bundles',
          text: <Trans id="NAVBAR.PRICE_BUNDLES">Price Bundles</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/price-bundles`);
          },
        },
        {
          id: 'pc-dashboard',
          text: <Trans id="NAVBAR.PC_DASHBOARD">PC Dashboard</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/pc-dashboard`);
          },
        },
      ],
    },
    {
      id: 'admin',
      text: <Trans id="NAVBAR.ADMIN">Admin</Trans>,
      icon: SecurityOutlinedIcon,
      items: [
        {
          id: 'avatar',
          text: <Trans id="NAVBAR.avatar">Avatar</Trans>,
          onClick: () => {
            history.push(AdminRoutes.Avatar.path);
          },
        },
        {
          id: 'coupons',
          text: <Trans id="NAVBAR.COUPONS">Coupons</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/coupons`);
          },
        },
        {
          id: 'languages',
          text: <Trans id="navbar.languages">Languages</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/languages`);
          },
        },
        {
          id: 'settings',
          text: <Trans id="NAVBAR.SETTINGS">Settings</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/settings`);
          },
        },
        {
          id: 'prices',
          text: <Trans id="NAVBAR.PRICES">Prices</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/prices`);
          },
        },
        {
          id: 'modules',
          text: <Trans id="NAVBAR.MODULES">Modules</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/modules`);
          },
        },
        {
          id: 'alerts',
          text: <Trans id="NAVBAR.ALERTS">Alerts</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/alerts`);
          },
        },
        {
          id: 'live-hours',
          text: <Trans id="NAVBAR.LIVE_HOURS">Live hours</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/live-hours`);
          },
        },
        {
          id: 'banner',
          text: <Trans id="navbar.banner" message="Banner" />,
          onClick: () => {
            history.push(`${Routes.admin.uri}/banner`);
          },
        },
      ],
    },
    {
      id: 'reports',
      text: <Trans id="NAVBAR.REPORTS">Reports</Trans>,
      icon: AssessmentOutlinedIcon,
      items: [
        {
          id: 'active-calls',
          text: <Trans id="NAVBAR.ACTIVE_CALLS">Active Calls</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/active-calls`);
          },
        },
        {
          id: 'active-searches',
          text: <Trans id="NAVBAR.ACTIVE_SEARCHES">Active Searches</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/active-searches`);
          },
        },
        {
          id: 'schedule',
          text: <Trans id="NAVBAR.SCHEDULE">Schedule</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/schedule`);
          },
        },
        {
          id: 'sockets',
          text: <Trans id="NAVBAR.SOCKETS">Sockets</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/sockets`);
          },
        },
        {
          id: 'coach-quizes',
          text: <Trans id="NAVBAR.COACH_QUIZES">Coach Quiz</Trans>,
          onClick: () => {
            history.push(`${Routes.admin.uri}/coach-quizes`);
          },
        },
        {
          id: 'coach-report',
          text: <Trans id="NAVBAR.COACH_REPORT" message="Coach Report" />,
          onClick: () => {
            history.push(`${Routes.admin.uri}/coach-report`);
          },
        },
        {
          id: 'coach-behaviors',
          text: <Trans id="NAVBAR.COACH_BEHAVIORS" message="Coach Behaviors" />,
          onClick: () => {
            history.push(`${Routes.admin.uri}/coach-behaviors`);
          },
        },
        {
          id: 'talking-too-fast',
          text: (
            <Trans id="NAVBAR.TALKING_TOO_FAST" message="Talking Too Fast" />
          ),
          onClick: () => {
            history.push(`${Routes.admin.uri}/talking-too-fast`);
          },
        },
      ],
    },
  ];
};
