import { useCallback, useEffect, useState } from 'react';
import { SettingsSdk } from '../sdk/SettingsSdk';
export const useSettings = (autoExecute = true) => {
    const [settings, setSettings] = useState();
    const getSettings = useCallback(async () => {
        const data = await SettingsSdk.getSettings();
        setSettings(data);
        return data;
    }, []);
    useEffect(() => {
        if (autoExecute) {
            getSettings();
        }
    }, []);
    return {
        settings,
        getSettings,
    };
};
