import { TalkingTooFastContext } from '../index';

export default function (this: TalkingTooFastContext) {
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const currentDay = new Date().getDate();
  let startDate = new Date();
  let endDate = new Date();

  if (currentMonth < 8) {
    startDate = new Date(`${currentYear}-01-01`);
    endDate = new Date(`${currentYear}-08-15`);
  } else if (currentMonth > 8) {
    startDate = new Date(`${currentYear}-08-16`);
    endDate = new Date(`${currentYear}-12-31`);
  } else if (currentMonth === 8) {
    if (currentDay < 16) {
      startDate = new Date(`${currentYear}-08-01`);
      endDate = new Date(`${currentYear}-08-15`);
    } else {
      startDate = new Date(`${currentYear}-08-16`);
      endDate = new Date(`${currentYear}-12-31`);
    }
  }

  this.produce((draft) => {
    draft.startDate = startDate;
    draft.endDate = endDate;
  });
}
