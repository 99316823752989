import React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  Box,
  Typography,
  Table,
  TableBody,
  Tooltip,
  IconButton,
} from '@mui/material';
import BodyTableCellWithLessPadding from './BodyTableCellWithLessPadding';
import { Trans } from '@lingui/react';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { VideoPlayer } from 'conversifi-shared-react/es6/components/VideoPlayer/index';
import { InstructorSdk } from '../../../../sdk/instructor.sdk';
import { S3_URL_REGEX } from 'conversifi-shared-react/src/constants/constants';
import { CallsInfo } from '../types/State';

interface Props {
  calls: CallsInfo[];
}

export default function ({ calls }: Props) {
  const [videoToPlay, setVideoToPlay] = React.useState<string | undefined>();

  return (
    <Box sx={{ margin: 1, width: '100%' }}>
      <Typography variant="h6" gutterBottom component="div">
        Calls
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">Call ID</TableCell>
            <TableCell align="center">Created At</TableCell>
            <TableCell align="center">Max call duration</TableCell>
            <TableCell align="center">Total audio duration</TableCell>
            <TableCell align="center">Total words spoken</TableCell>
            <TableCell align="center">Words per minute</TableCell>
            <TableCell align="center">Watch video</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {calls.map(
            ({ callId, aiReport, createdAt, zoom, callConfigValues }) => (
              <TableRow key={callId.toString()}>
                <BodyTableCellWithLessPadding>
                  {callId}
                </BodyTableCellWithLessPadding>
                <BodyTableCellWithLessPadding>
                  {createdAt}
                </BodyTableCellWithLessPadding>
                <BodyTableCellWithLessPadding>
                  {callConfigValues.maxCallDuration} min
                </BodyTableCellWithLessPadding>
                <BodyTableCellWithLessPadding>
                  {aiReport?.coachAudioMonitoring.totalDuration.toFixed(2)}{' '}
                  seconds
                </BodyTableCellWithLessPadding>
                <BodyTableCellWithLessPadding>
                  {aiReport?.coachAudioMonitoring.totalWords}
                </BodyTableCellWithLessPadding>
                <BodyTableCellWithLessPadding>
                  {aiReport?.coachAudioMonitoring.wordsPerMinute}
                </BodyTableCellWithLessPadding>
                <BodyTableCellWithLessPadding>
                  <>
                    <Tooltip
                      title={
                        <Trans id="admin.paid-coach-application.coach-video-player.watch-video">
                          Watch video
                        </Trans>
                      }
                    >
                      <IconButton
                        onClick={async () => {
                          const url = S3_URL_REGEX.test(
                            zoom?.composition?.media ?? ''
                          )
                            ? await InstructorSdk.getCallMediaData(
                                callId.toString()
                              )
                            : `/api/calls/${callId}/media`;

                          setVideoToPlay(url);
                        }}
                      >
                        <VideocamOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    {videoToPlay && (
                      <VideoPlayer
                        url={videoToPlay}
                        onClose={() => setVideoToPlay(undefined)}
                        obfuscateBackground
                        closeOnClickOutside
                      />
                    )}
                  </>
                </BodyTableCellWithLessPadding>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </Box>
  );
}
