import React, { FC } from 'react';
import {
  Typography,
  Box,
  Paper,
  TableContainer,
  Table,
  TableBody,
  Stack,
  Button,
} from '@mui/material';
import { Trans } from '@lingui/react';
import { LinearProgressContext } from 'conversifi-shared-react/es6/components/LinearProgressContext';
import { BaseProvider } from 'conversifi-shared-react/es6/BaseProvider';
import { State } from './types/State';
import { Props } from './types/Props';
import { TalkingTooFastPublicContext } from './types/PublicContext';
import { PublicApi } from './types/PublicApi';
import { SnackbarContext } from 'conversifi-shared-react/es6/components/SnackbarContext';
import { LinguiContext } from 'conversifi-shared-react/es6/components/LinguiContext';
import TableHeaderPlus from '../../../components/TableHeaderPlus';
import { CountriesContext } from 'conversifi-shared-react/es6/components/CountriesContext';
import { LanguagesContext } from 'conversifi-shared-react/es6/components/LanguagesContext';
import { DialogContext } from 'conversifi-shared-react/es6/components/DialogContext';
import { getHeadCells } from './methods/getHeadcells';
import fetchTalkingTooFastReport from './methods/fetchTalkingTooFastReport';
import postCoachNotes from './methods/postCoachNotes';
import handleRowClick from './methods/handleRowClick';
import handleSelectedRoles from './methods/handleSelectedRoles';
import renderRows from './methods/renderRows';
import { SORT_ORDER } from 'conversifi-shared-react/es6/types/TableMaterialUI';
import setDates from './methods/setDates';
import { useSettings } from 'conversifi-shared-react/es6/hooks/useSettings';
import WordPerMinuteCallThreshold from './components/WordPerMinuteCallThreshold';
import fetchSettings from './methods/fetchSettings';
import updateSettings from './methods/updateSettings';
import downloadCsv from './methods/downloadCsv';

export class TalkingTooFastContext
  extends BaseProvider<Props, PublicApi>
  implements PublicApi
{
  protected readonly PublicContext = TalkingTooFastPublicContext;
  readonly className = 'TalkingTooFastContext';
  static useCtx = () => React.useContext(TalkingTooFastPublicContext);

  constructor(props: Props) {
    super(props);
    this.bindAll();
  }

  getInitialState(): State {
    return {
      endDate: new Date('2024-12-31'),
      startDate: new Date('2024-06-01'),
      filters: {
        language: '',
        country: '',
        selectedRole: '',
      },
      sortBy: 'totalFifteenMinuteCallsWithProblems',
      loading: false,
      talkingTooFastReport: [],
      currentAdminNote: '',
      displayRows: [],
    };
  }

  protected getPublicApi(): PublicApi {
    return {
      state: this.state,
    };
  }

  componentDidMount(): void {
    this.setDates();
    this.fetchSettings();
  }

  componentDidUpdate(
    _prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ): void {
    if (
      this.state.startDate !== prevState.startDate ||
      this.state.endDate !== prevState.endDate ||
      this.state.filters !== prevState.filters ||
      this.state.sortBy !== prevState.sortBy
    ) {
      this.fetchTalkingTooFastReport();
    }
  }

  protected getHeadCells = getHeadCells;
  protected fetchTalkingTooFastReport = fetchTalkingTooFastReport;
  protected postCoachNotes = postCoachNotes;
  protected handleRowClick = handleRowClick;
  protected renderRows = renderRows;
  protected handleSelectedRoles = handleSelectedRoles;
  protected setDates = setDates;
  protected fetchSettings = fetchSettings;
  protected updateSettings = updateSettings;
  protected downloadCsv = downloadCsv;

  render() {
    return (
      <this.PublicContext.Provider value={this.getPublicApi()}>
        <Box display="flex" justifyContent="center">
          <Paper sx={{ maxWidth: '90vw', minWidth: '70vw' }}>
            <Box p={2}>
              <Typography
                color="primary"
                variant="h4"
                textAlign="center"
                gutterBottom
              >
                <Trans
                  id="admin.talking-too-fast.title"
                  message="Talking Too Fast"
                />
              </Typography>
              {this.state.settings && (
                <Stack
                  direction="row"
                  spacing={2}
                  mb={2}
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <WordPerMinuteCallThreshold
                    initialWpmThreshold={
                      this.state.settings.coachAudioMonitoring
                        .fifteenMinuteCallsWPMThreshold
                    }
                    updateSettings={(value) => {
                      if (this.state.settings) {
                        this.updateSettings({
                          ...this.state.settings,
                          coachAudioMonitoring: {
                            ...this.state.settings.coachAudioMonitoring,
                            fifteenMinuteCallsWPMThreshold: value,
                          },
                        });
                      }
                    }}
                    title="Fifteen Minute Calls WPM Threshold"
                  />
                  <WordPerMinuteCallThreshold
                    initialWpmThreshold={
                      this.state.settings.coachAudioMonitoring
                        .tenMinuteCallsWPMThreshold
                    }
                    updateSettings={(value) => {
                      if (this.state.settings) {
                        this.updateSettings({
                          ...this.state.settings,
                          coachAudioMonitoring: {
                            ...this.state.settings.coachAudioMonitoring,
                            tenMinuteCallsWPMThreshold: value,
                          },
                        });
                      }
                    }}
                    title="Ten Minute Calls WPM Threshold"
                  />
                  <Button
                    variant="contained"
                    onClick={() => {
                      this.downloadCsv();
                    }}
                    sx={{ height: 56 }}
                  >
                    Download CSV
                  </Button>
                </Stack>
              )}
              <TableContainer sx={{ height: '60vh' }}>
                <Table stickyHeader>
                  <TableHeaderPlus
                    headCells={this.getHeadCells()}
                    order={SORT_ORDER.DESC}
                    orderBy={this.state.sortBy}
                    onRequestSort={(_, property) => {
                      this.produce((draft) => {
                        console.info('sortBy', property);
                        draft.sortBy = property as any;
                      });
                    }}
                  />
                  <TableBody>{this.renderRows()}</TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Box>
      </this.PublicContext.Provider>
    );
  }
}

const TalkingTooFast: FC = () => {
  const snackbarCtx = SnackbarContext.useCtx();
  const linearProgressCtx = LinearProgressContext.useCtx();
  const countriesCtx = CountriesContext.useCtx();
  const trans = LinguiContext.useCtx().trans;
  const languagesCtx = LanguagesContext.useCtx();
  const dialogCtx = DialogContext.useCtx();
  const { getSettings } = useSettings(false);

  return (
    <TalkingTooFastContext
      snackbarCtx={snackbarCtx}
      linearProgressCtx={linearProgressCtx}
      trans={trans}
      countriesCtx={countriesCtx}
      languagesCtx={languagesCtx}
      dialogCtx={dialogCtx}
      getSettings={getSettings}
    />
  );
};

export default TalkingTooFast;
