import { SettingsSdk } from 'conversifi-shared-react/es6/sdk/SettingsSdk';
import { TalkingTooFastContext } from '..';
import { PartialSettings } from '../../../Admin/settings';

export default function (
  this: TalkingTooFastContext,
  partialSettings: PartialSettings
) {
  SettingsSdk.updateSettings(partialSettings).then(() => {
    this.fetchSettings();

    this.fetchTalkingTooFastReport();
  });
}
