import { Routes } from '../routes';

export const AdminRoutes = {
  Users: {
    path: `${Routes.admin.uri}/users`,
    SearchUsers: {
      path: `${Routes.admin.uri}/users/search`,
    },
    PaidCoachApplications: {
      path: `${Routes.admin.uri}/users/paid-coach-applications`,
    },
    EditProfile: {
      path: `${Routes.admin.uri}/users/:id/edit-profile`,
    },
    EditBalance: {
      path: `${Routes.admin.uri}/users/:id/edit-balance`,
    },
    ResetPassword: {
      path: `${Routes.admin.uri}/users/:id/reset-password`,
    },
    DeleteUser: {
      path: `${Routes.admin.uri}/users/:id/delete-user`,
    },
    VerificationEmail: {
      path: `${Routes.admin.uri}/users/verification-email`,
    },
    CallsList: {
      path: `${Routes.admin.uri}/users/:id/calls-list`,
    },
  },
  MAv2: {
    path: `${Routes.admin.uri}/mav2`,
    Matches: {
      match: new RegExp(`${Routes.admin.uri}/mav2/matches`),
      path: `${Routes.admin.uri}/mav2/matches`,
    },
    SqlQuery: {
      match: new RegExp(`${Routes.admin.uri}/mav2/sql-query`),
      path: `${Routes.admin.uri}/mav2/sql-query`,
    },
    Rules: {
      match: new RegExp(`${Routes.admin.uri}/mav2/rules`),
      path: `${Routes.admin.uri}/mav2/rules`,
    },
    Priorities: {
      match: new RegExp(`${Routes.admin.uri}/mav2/priorities`),
      path: `${Routes.admin.uri}/mav2/priorities`,
    },
  },
  Alerts: {
    match: new RegExp(`${Routes.admin.uri}/alerts`),
    path: `${Routes.admin.uri}/alerts`,

    List: {
      match: new RegExp(`${Routes.admin.uri}/alerts/list`),
      path: `${Routes.admin.uri}/alerts/list`,
    },

    Edit: {
      match: new RegExp(`${Routes.admin.uri}/alerts/edit`),
      path: `${Routes.admin.uri}/alerts/edit`,
    },

    Create: {
      match: new RegExp(`${Routes.admin.uri}/alerts/new`),
      path: `${Routes.admin.uri}/alerts/new`,
    },
  },
  Reports: {
    path: `${Routes.admin.uri}/reports`,
    ActiveCalls: {
      match: new RegExp(`${Routes.admin.uri}/active-calls`),
      path: `${Routes.admin.uri}/active-calls`,
    },
    ActiveSearches: {
      match: new RegExp(`${Routes.admin.uri}/active-searches`),
      path: `${Routes.admin.uri}/active-searches`,
    },
    Schedule: {
      match: new RegExp(`${Routes.admin.uri}/schedule`),
      path: `${Routes.admin.uri}/schedule`,
    },
    Sockets: {
      match: new RegExp(`${Routes.admin.uri}/sockets`),
      path: `${Routes.admin.uri}/sockets`,
    },
    CoachQuizes: {
      match: new RegExp(`${Routes.admin.uri}/coach-quizes`),
      path: `${Routes.admin.uri}/coach-quizes`,
    },
    CoachReport: {
      match: new RegExp(`${Routes.admin.uri}/coach-report`),
      path: `${Routes.admin.uri}/coach-report`,
    },
    CoachBehaviors: {
      match: new RegExp(`${Routes.admin.uri}/coach-behaviors`),
      path: `${Routes.admin.uri}/coach-behaviors`,
    },
    TalkingTooFast: {
      match: new RegExp(`${Routes.admin.uri}/talking-too-fast`),
      path: `${Routes.admin.uri}/talking-too-fast`,
    },
  },
  Universities: {
    List: {
      match: new RegExp(`${Routes.admin.uri}/universities`),
      path: `${Routes.admin.uri}/universities`,
    },
  },
  Avatar: {
    match: new RegExp(`${Routes.admin.uri}/avatar`),
    path: `${Routes.admin.uri}/avatar`,
  },
};
