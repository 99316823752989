import { AdminSdk } from '../../../../sdk/admin.sdk';
import { TalkingTooFastContext } from '../index';

export default async function (this: TalkingTooFastContext) {
  try {
    this.produce((draft) => {
      draft.loading = true;
    });

    const data = await AdminSdk.Coaches.getTalkingTooFastReport({
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      filters: this.state.filters,
      sortBy: this.state.sortBy,
    });

    this.produce((draft) => {
      draft.talkingTooFastReport = data;
      draft.loading = false;
    });
  } catch (err) {
    console.error(err);

    this.props.snackbarCtx.setMessage({
      message: 'Error fetching report',
      severity: 'error',
    });

    this.produce((draft) => {
      draft.loading = false;
    });
  }
}
