import React from 'react';
import { Button, InputLabel, OutlinedInput, Stack } from '@mui/material';

interface Props {
  initialWpmThreshold: number;
  updateSettings: (value: number) => void;
  title: string;
}

export default function ({
  initialWpmThreshold,
  updateSettings,
  title,
}: Props) {
  const [wpmThreshold, setWPMThreshold] =
    React.useState<number>(initialWpmThreshold);

  return (
    <Stack direction="column" spacing={1}>
      <InputLabel>{title}</InputLabel>
      <OutlinedInput
        defaultValue={initialWpmThreshold}
        type="number"
        onChange={(e) => {
          const value = e.target.value;
          if (value === '') {
            setWPMThreshold(0);
          } else {
            const parsedValue = parseInt(value, 10);
            if (!isNaN(parsedValue)) {
              setWPMThreshold(parsedValue);
            }
          }
        }}
        endAdornment={
          <Button
            variant="contained"
            onClick={() => {
              updateSettings(wpmThreshold);
            }}
          >
            Save
          </Button>
        }
      />
    </Stack>
  );
}
