import React from 'react';
import { HeadCell } from 'conversifi-shared-react/es6/types/TableMaterialUI';
import { TalkingTooFastContext } from '../index';
import { ListItemText, MenuItem, Radio, Select, Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { Trans } from '@lingui/react';
import { ALL_ROLES_INITIALS } from '../../activeSearches/types/miscellaneous';

const getCommonStyles = (_theme: Theme, extras?: CSSProperties) => {
  return {
    maxWidth: 85,
    ...extras,
  };
};

export function getHeadCells(this: TalkingTooFastContext): HeadCell[] {
  return [
    {
      id: 'dropdown',
      label: '',
    },
    {
      id: 'name',
      label: (
        <Trans
          id="admin.active-searches.table.name-column-title"
          message="Name"
        />
      ),

      align: 'center',
    },
    {
      id: 'language',
      label: (
        <Trans
          id="admin.paid-coach-application.table-applications.language"
          message="Language"
        />
      ),
      tableCellSx(theme) {
        return getCommonStyles(theme);
      },
      align: 'center',
      filter: {
        customFilter: (
          <Select
            defaultValue="All"
            variant="standard"
            onChange={(event: any) => {
              if (event.target.value === 'All') {
                this.produce((draft) => {
                  draft.filters.language = '';
                });
              } else {
                this.produce((draft) => {
                  draft.filters.language = event.target.value;
                });
              }
            }}
            sx={{
              fontSize: 14,
              fontWeight: 550,
              ':before': { borderBottomWidth: '3px' },
              ':after': { borderBottomWidth: '3px' },
              ':hover:not(.Mui-disabled):before': {
                borderBottom: '3px solid rgba(0, 0, 0, 0.6) !important',
              },
            }}
          >
            <MenuItem key="All" value="All">
              ALL
            </MenuItem>
            {this.props.languagesCtx.getEnabledLanguages().map((language) => (
              <MenuItem key={language.langCode} value={language.langCode}>
                {language.langCode.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        ),
      },
    },
    {
      id: 'country',
      label: (
        <Trans
          id="admin.paid-coach-application.table-applications.country"
          message="Country"
        />
      ),
      tableCellSx(theme) {
        return getCommonStyles(theme);
      },
      align: 'center',
      filter: {
        customFilter: (
          <Select
            defaultValue="All"
            variant="standard"
            onChange={(event: any) => {
              if (event.target.value === 'All') {
                this.produce((draft) => {
                  draft.filters.country = '';
                });
              } else {
                this.produce((draft) => {
                  draft.filters.country = event.target.value;
                });
              }
            }}
            sx={{
              fontSize: 14,
              fontWeight: 550,
              ':before': { borderBottomWidth: '3px' },
              ':after': { borderBottomWidth: '3px' },
              ':hover:not(.Mui-disabled):before': {
                borderBottom: '3px solid rgba(0, 0, 0, 0.6) !important',
              },
            }}
          >
            <MenuItem key="All" value="All">
              ALL
            </MenuItem>
            {this.props.countriesCtx.getCountries(true).map((country) => (
              <MenuItem key={country.alpha2Code} value={country.alpha2Code}>
                {country.alpha2Code.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        ),
      },
    },
    {
      id: 'role',
      label: <Trans id="instructors.details.role" message="Role" />,
      tableCellSx(theme) {
        return getCommonStyles(theme, { minWidth: 110 });
      },
      align: 'center',
      filter: {
        customFilter: (
          <Select
            onChange={this.handleSelectedRoles}
            displayEmpty
            renderValue={() =>
              !this.state.filters.selectedRole
                ? 'ALL'
                : this.state.filters.selectedRole
            }
            // @ts-ignore
            value={this.state.filters.selectedRoles}
            variant="standard"
            sx={{
              fontWeight: 550,
              fontSize: 14,
              ':before': { borderBottomWidth: '3px' },
              ':after': { borderBottomWidth: '3px' },
              ':hover:not(.Mui-disabled):before': {
                borderBottom: '3px solid rgba(0, 0, 0, 0.6) !important',
              },
            }}
          >
            <MenuItem key="All" value="">
              <Radio checked={this.state.filters.selectedRole === ''} />
              <ListItemText primary={'All'} />
            </MenuItem>
            {ALL_ROLES_INITIALS.map((role) => {
              if (role === 'RC') return null;

              return (
                <MenuItem key={role} value={role}>
                  <Radio checked={this.state.filters.selectedRole === role} />
                  <ListItemText primary={role} />
                </MenuItem>
              );
            })}
          </Select>
        ),
      },
    },
    {
      id: 'totalCalls',
      label: (
        <Trans id="instructors.students.total-calls" message="Total Calls" />
      ),
      tableCellSx(theme) {
        return getCommonStyles(theme, { minWidth: 120, maxWidth: '100%' });
      },
      sort: true,
      sortDirection: 'asc',
      align: 'center',
    },
    {
      id: 'totalFifteenMinuteCallsWithProblems',
      label: (
        <Trans
          id="admin.coach-ai-report.talking-too-fast-calls"
          message="Too Fast (15min)"
        />
      ),
      tableCellSx(theme) {
        return getCommonStyles(theme, { minWidth: 150, maxWidth: '100%' });
      },
      sort: true,
      sortDirection: 'asc',
      align: 'center',
    },
    {
      id: 'totalTenMinuteCallsWithProblems',
      label: (
        <Trans
          id="admin.coach-ai-report.talking-too-fast-calls"
          message="Too Fast (10min)"
        />
      ),
      tableCellSx(theme) {
        return getCommonStyles(theme, { minWidth: 150, maxWidth: '100%' });
      },
      sort: true,
      sortDirection: 'asc',
      align: 'center',
    },
    {
      id: 'averageWordPerMinuteSpeed',
      label: (
        <Trans
          id="admin.coach-ai-report.average-speed"
          message="Average Speed (WPM)"
        />
      ),
      tableCellSx(theme) {
        return getCommonStyles(theme, { minWidth: 150, maxWidth: '100%' });
      },
      sort: true,
      sortDirection: 'asc',
      align: 'center',
    },
    {
      id: 'adminNotes',
      label: <Trans id="" message="Notes" />,
      tableCellSx(theme) {
        return getCommonStyles(theme, { minWidth: 80, maxWidth: '100%' });
      },
      align: 'center',
    },
  ];
}
