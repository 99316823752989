import { getJwt } from '../util';
const APPLICATION_JSON = 'application/json';
function settingResponse(response) {
    return response.status === 204
        ? Promise.resolve()
        : response.json().then((json) => json);
}
export class SettingsSdk {
    static getSettings() {
        const jwt = getJwt();
        return !jwt
            ? Promise.reject('User not logged in')
            : fetch('/api/settings', {
                method: 'GET',
                headers: {
                    Authorization: jwt,
                    'Content-Type': APPLICATION_JSON,
                },
            }).then(settingResponse);
    }
    static updateSettings(settings) {
        const jwt = getJwt();
        return !jwt
            ? Promise.reject('User not logged in')
            : fetch('/api/admin/settings', {
                method: 'PUT',
                headers: {
                    Authorization: jwt,
                    'Content-Type': APPLICATION_JSON,
                },
                body: JSON.stringify(settings),
            }).then(settingResponse);
    }
    static async getSettingsAnonymous() {
        const response = await fetch('/api/settings');
        return settingResponse(response);
    }
}
