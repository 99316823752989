import { SelectChangeEvent } from '@mui/material';
import { TalkingTooFastContext } from '..';

export default function (
  this: TalkingTooFastContext,
  event: SelectChangeEvent<string>
) {
  const {
    target: { value },
  } = event;

  this.produce((draft) => {
    draft.filters.selectedRole = value;
  });
}
