import { TalkingTooFastContext } from '../index';

export default function (this: TalkingTooFastContext) {
  let csv = [];
  const lineBreak = ['', '', '', '', '', '', '', ''];

  csv.push([
    'Talking Too Fast Report',
    '',
    '',
    'Fifteen Minute Call Threshold',
    '',
    'Ten Minute Call Threshold',
    '',
    '',
    '',
  ]);

  csv.push([
    '',
    '',
    '',
    this.state.settings?.coachAudioMonitoring.fifteenMinuteCallsWPMThreshold,
    '',
    this.state.settings?.coachAudioMonitoring.tenMinuteCallsWPMThreshold,
    '',
    '',
    '',
  ]);

  csv.push(lineBreak);

  csv.push([
    'Name',
    'Language',
    'Country',
    'Role',
    'Total Calls',
    'Talking Too Fast Calls (15min)',
    'Talking Too Fast Calls (10min)',
    'Average speed (WPM)',
    'Notes',
  ]);

  this.state.talkingTooFastReport.forEach((coachReport) => {
    let coachRole = 'PC';
    if (coachReport.coach.paidCoachApplication.isScheduledPaidCoach) {
      coachRole = 'SC';
    } else if (coachReport.coach.paidCoachApplication.certifiedBeginner) {
      coachRole = 'CBC';
    }

    csv.push([
      coachReport.coach.name,
      coachReport.coach.language.code,
      coachReport.coach.language.country,
      coachRole,
      coachReport.calls,
      coachReport.totalFifteenMinuteCallsWithProblems,
      coachReport.totalTenMinuteCallsWithProblems,
      coachReport.averageWordPerMinuteSpeed,
      coachReport.coach.paidCoachApplication.adminNotes,
    ]);

    csv.push([
      '',
      '',
      'Call ID',
      'Created At',
      'Max call duration',
      'Total audio duration',
      'Total words spoken',
      'Words per minute',
    ]);

    coachReport.callsWithProblems.forEach((call) => {
      csv.push([
        '',
        '',
        call.callId,
        call.createdAt,
        call.callConfigValues.maxCallDuration,
        call.aiReport?.coachAudioMonitoring.totalDuration,
        call.aiReport?.coachAudioMonitoring.totalWords,
        call.aiReport?.coachAudioMonitoring.wordsPerMinute,
      ]);
    });

    // add a blank row between each coach
    csv.push(lineBreak);
  });

  const csvContent =
    'data:text/csv;charset=utf-8,' + csv.map((e) => e.join(',')).join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'talking-too-fast-report.csv');
  document.body.appendChild(link);
  link.click();
}
