import { RecordId } from 'conversifi-types/src/commons';
import { TalkingTooFastContext } from '../index';

export default function (this: TalkingTooFastContext, _id: RecordId) {
  if (this.state.displayRows.includes(_id)) {
    this.produce((draft) => {
      draft.displayRows = draft.displayRows.filter((id) => id !== _id);
    });
  } else {
    this.produce((draft) => {
      draft.displayRows = [...draft.displayRows, _id];
    });
  }
}
